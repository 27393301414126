@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.section {
  padding: clamp(30px, 7vw, 100px) 0;

  &__nav {
    @include flex-all-sb;
    gap: 16px;
    margin-bottom: clamp(40px, 7vw, 100px);

    &--left {
      justify-content: flex-start;
      gap: 16px clamp(40px, 7.4vw, 104px);
    }
  }

  &__desc {
    max-width: 465px;
    width: 100%;
  }

  &--team {
    .section__desc {
      max-width: 704px;
    }
  }

  @include media(tablet) {
    &__nav {
      flex-direction: column;
      align-items: flex-start;
    }

    &__desc {
      max-width: 100%;
    }
  }
}