@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 14px clamp(22px, 2vw, 28px);
  border: 2px solid var(--color-green-400);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-600);
  font-size: clamp(16px, 2.5vw, 20px);
  line-height: 1.22;
  color: var(--color-green-400) !important;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  transition-property: background, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &:hover,
  &--reverse {
    color: var(--color-white) !important;
    background: var(--color-green-400);
  }

  &--reverse {
    &:hover {
      background: var(--color-white);
      color: var(--color-green-400) !important;
    }
  }

  &--blue {
    background-color: var(--color-blue-400);
    color: var(--color-white) !important;
    border-color: var(--color-blue-400);

    &:hover {
      background-color: var(--color-white);
      color: var(--color-blue-400) !important;
    }
  }

  &--desktop {
    @include media(mobile-l) {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include media(mobile-l) {
      display: block;
    }
  }

  &--back {
    position: relative;
    padding-left: 66px;

    &::before {
      @include cover-background;
      content: "";
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url("../images/icons/arrow-icon.svg");
      transition: filter 0.2s ease-in-out;
      filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(4936%) hue-rotate(64deg) brightness(86%) contrast(86%);
    }

    &:hover::before {
      filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(175deg) brightness(103%) contrast(95%);
    }

    @include media(mobile-l) {
      padding-left: 44px;

      &::before {
        left: 6px;
      }
    }
  }
}