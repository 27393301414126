@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding-block: 50px;
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-400);
  font-size: 18px;
  color: var(--color-black);

  h1,
  h2 {
    text-transform: capitalize;
  }

  h1 {
    margin-bottom: 24px;
    font-size: clamp(26px, 3.4vi, 48px);
  }

  h2 {
    margin-bottom: 16px;
    font-size: clamp(20px, 2vi, 24px);
  }

  .policy__content:not(:last-child),
  .policy__content > *:not(:last-child, h2) {
    margin-bottom: 32px
  }

  ul {
    padding-left: clamp(16px, 3vw, 32px);
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: "\2714";
      position: absolute;
      left: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  a {
    color: var(--color-green-400);
    text-decoration: underline;
    text-underline-offset: 6px;
  }

  strong {
    font-size: clamp(18px, 3vw, 20px);
    font-weight: var(--fw-500);
  }
}