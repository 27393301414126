@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital@0;1&family=Poppins&display=swap";
:root {
  --content-width: 1170px;
  --content-large: 1320px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --container-large: calc(var(--content-large)  + (var(--container-offset) * 2));
  --font-family-primary: "Montserrat", sans-serif;
  --font-family-secondary: "Nunito", sans-serif;
  --font-family-accent: "Poppins", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-dark: #000000c2;
  --color-green-400: #29ce69;
  --color-grey-200: #e3e7dc;
  --color-grey-300: #aaa;
  --color-grey-400: #898989;
  --color-blue-400: #3685fb;
  --color-blue-700: #091f3f;
  --color-blue-800: #030f1f;
  --radius-main: 50px;
  --radius-xl: 100px;
  --radius-l: 24px;
  --radius-m: 16px;
  --radius-s: 8px;
  --radius-xs: 4px;
  --radius-full: 50%;
  --gradient-primary: linear-gradient(0deg, #f8f8f800, #f8f8f800), linear-gradient(180deg, #091f3f00 79.24%, #030f1f 86.96%);
  --gradient-overlay: linear-gradient(136deg, #ffffffa6 30%, #387518a6 85%);
  --shadow-primary: 2px 2px 16px 2px #0000007f;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: -.002em;
  color: var(--color-grey-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 2vi, 20px);
  font-style: normal;
  line-height: 1.22;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.page--home .accent .section__nav .btn {
  display: block;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.container--large {
  max-width: var(--container-large);
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .overlay:before {
    background: var(--gradient-overlay);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .title, summary {
  font-weight: var(--fw-700);
  letter-spacing: -.024em;
  color: var(--color-blue-700);
  line-height: 1.2;
  overflow: hidden;
}

h1.bright, h2.bright, h3.bright, .title.bright, summary.bright {
  color: var(--color-white);
}

@media only screen and (max-width: 768px) {
  h1 br, h2 br, h3 br, .title br, summary br {
    display: none;
  }
}

h1 {
  font-size: clamp(32px, 4.5vi, 64px);
}

h2 {
  font-size: clamp(26px, 3.4vi, 48px);
}

h3, summary, .title {
  font-size: clamp(20px, 2.3vi, 32px);
}

@media only screen and (max-width: 768px) {
  .title {
    font-weight: var(--fw-600);
  }
}

p {
  letter-spacing: -.5px;
  line-height: 1.22;
}

input, textarea {
  font-weight: var(--fw-600);
  letter-spacing: -.002em;
  color: var(--color-blue-400);
  font-size: clamp(20px, 2vi, 24px);
  line-height: 1.22;
}

input::placeholder, textarea::placeholder {
  color: var(--color-grey-400);
  font-weight: var(--fw-500);
}

.link {
  font-weight: var(--fw-500);
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.22;
}

.link br {
  display: none;
}

@media only screen and (max-width: 576px) {
  .link {
    text-align: center;
    line-height: 1.6;
  }

  .link br {
    display: block;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: clamp(12px, 2.8vw, 40px) 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: clamp(150px, 14vw, 201px);
  width: 100%;
  margin-right: clamp(10px, 2.3vw, 32px);
}

.nav__inner {
  max-width: 986px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.nav__list {
  grid-template-columns: repeat(3, auto);
  align-items: stretch;
  gap: 0 clamp(10px, 4.8vw, 66px);
  margin-inline: 0 clamp(10px, 2.3vw, 32px);
  display: grid;
}

@media only screen and (max-width: 992px) {
  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  color: var(--color-dark);
  white-space: nowrap;
  font-size: 16px;
  transition: border-bottom .3s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  color: var(--color-green-400);
  border-bottom: 2px solid var(--color-green-400);
}

.nav__btns {
  align-items: center;
  gap: clamp(16px, 1.8vw, 24px);
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }

  .nav:not(.nav--footer) .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    height: calc(var(--vh));
    max-width: 100%;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav:not(.nav--footer) .nav__list {
    z-index: -1;
    grid-template-columns: auto;
    justify-items: center;
    margin-right: 0;
  }

  .nav--footer {
    flex-direction: column;
    align-items: center;
  }

  .nav--footer .logo {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .nav--footer .nav__inner {
    flex-direction: column;
  }

  .nav--footer .nav__list {
    justify-items: center;
    gap: 16px clamp(10px, 4.8vw, 66px);
    margin-right: 0;
  }

  .nav--footer .nav__item {
    margin-bottom: 0;
  }

  .nav--footer .nav__btns {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    display: flex;
  }
}

[class].btn {
  border: 2px solid var(--color-green-400);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-600);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  margin: 0;
  padding: 14px clamp(22px, 2vw, 28px);
  font-size: clamp(16px, 2.5vw, 20px);
  line-height: 1.22;
  transition-property: background, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  color: var(--color-green-400) !important;
}

[class].btn:hover, [class].btn--reverse {
  background: var(--color-green-400);
  color: var(--color-white) !important;
}

[class].btn--reverse:hover {
  background: var(--color-white);
  color: var(--color-green-400) !important;
}

[class].btn--blue {
  background-color: var(--color-blue-400);
  border-color: var(--color-blue-400);
  color: var(--color-white) !important;
}

[class].btn--blue:hover {
  background-color: var(--color-white);
  color: var(--color-blue-400) !important;
}

@media only screen and (max-width: 768px) {
  [class].btn--desktop {
    display: none;
  }
}

[class].btn--mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  [class].btn--mobile {
    display: block;
  }
}

[class].btn--back {
  padding-left: 66px;
  position: relative;
}

[class].btn--back:before {
  content: "";
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate() invert(98%) sepia() saturate(4936%) hue-rotate(64deg) brightness(86%) contrast(86%);
  background-image: url("../images/icons/arrow-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: filter .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%);
}

[class].btn--back:hover:before {
  filter: brightness(0) saturate() invert() sepia(10%) saturate(7490%) hue-rotate(175deg) brightness(103%) contrast(95%);
}

@media only screen and (max-width: 768px) {
  [class].btn--back {
    padding-left: 44px;
  }

  [class].btn--back:before {
    left: 6px;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-green-400);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened.nav:not(.nav--footer) .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.section {
  padding: clamp(30px, 7vw, 100px) 0;
}

.section__nav {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: clamp(40px, 7vw, 100px);
  display: flex;
}

.section__nav--left {
  justify-content: flex-start;
  gap: 16px clamp(40px, 7.4vw, 104px);
}

.section__desc {
  max-width: 465px;
  width: 100%;
}

.section--team .section__desc {
  max-width: 704px;
}

@media only screen and (max-width: 992px) {
  .section__nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .section__desc {
    max-width: 100%;
  }
}

.box__info {
  max-width: 1320px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: clamp(40px, 7vw, 85px);
  margin: 0 auto;
  display: flex;
}

.box__info--right .box__image {
  border-top-left-radius: var(--radius-main);
  border-bottom-left-radius: var(--radius-main);
  align-self: flex-end;
}

.box__info--left {
  flex-direction: row-reverse;
}

.box__info--left .box__image {
  border-top-right-radius: var(--radius-main);
  border-bottom-right-radius: var(--radius-main);
}

.box__info--bordered .box__image {
  border-radius: var(--radius-main);
  width: 100%;
}

.box__info--accent {
  flex-direction: column;
  align-items: stretch;
  gap: 48px;
}

.box__info--accent:last-of-type {
  margin-bottom: 0;
}

.box__info--accent .box__content :not(:last-child) {
  margin-bottom: 22px;
}

.box__info--accent .box__content h2, .box__info--accent .box__content p, .box__info--accent .box__content li {
  color: var(--color-grey-400);
}

.box__info--accent .box__content h2 {
  font-size: clamp(22px, 3.4vi, 40px);
  line-height: 1.2;
}

.box__info--accent.box__info--left .box__content {
  align-self: center;
}

.box__info:not(:last-of-type) {
  margin-bottom: clamp(40px, 9vw, 122px);
}

.box__name {
  color: var(--color-green-400);
}

.box__content h2 {
  margin-bottom: 12px;
}

.box__content :not(:last-child, h2, li) {
  margin-bottom: 24px;
}

.box__content ul {
  padding-left: clamp(20px, 2.2vw, 30px);
}

.box__content li {
  letter-spacing: -.5px;
  line-height: 1.3;
  list-style: disc;
  display: list-item;
}

.box--overview, .box--activities {
  padding-bottom: clamp(30px, 7vw, 62px);
}

.box--ecosystem {
  padding-block: clamp(30px, 7vw, 62px);
}

.box--activities .box__info:not(.box__info--accent:last-of-type) {
  margin-bottom: 48px;
}

.box--activities .box__content:not(:last-of-type) {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.box--commitment {
  padding-block: clamp(30px, 7vw, 68px) clamp(30px, 7vw, 62px);
}

.box--commitment .box__info {
  border-bottom: 2px solid var(--color-grey-200);
  align-items: flex-start;
  margin-bottom: clamp(30px, 7vw, 60px);
  padding-bottom: clamp(30px, 7vw, 90px);
}

.box--commitment .box__content {
  max-width: 422px;
  width: 100%;
}

.box--commitment .box__content h2 {
  margin-bottom: 16px;
}

.box--environmental {
  padding-block: clamp(30px, 7vw, 62px) 0;
}

.box--environmental .box__info {
  align-items: flex-start;
}

.box--environmental .box__content h2 {
  margin-bottom: 24px;
}

.box--single .box__image:not(.box__image--long) {
  aspect-ratio: 3 / 2;
}

@media only screen and (max-width: 1280px) {
  .box--environmental {
    padding-block: clamp(30px, 7vw, 62px);
  }

  .box--environmental .box__info {
    align-items: stretch;
  }

  .box--single .box__image {
    max-width: 50%;
  }
}

@media only screen and (max-width: 1180px) {
  .box__image {
    max-width: 50%;
    width: 100%;
  }

  .box__image:not(.box__image--long) {
    aspect-ratio: 4 / 2;
  }

  .box__info--accent .box__image, .box__info--bordered .box__image {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .box__info {
    flex-direction: column;
  }

  .box__info--right .box__image {
    border-top-left-radius: var(--radius-m);
    border-bottom-left-radius: var(--radius-m);
  }

  .box__info--left .box__image {
    border-top-right-radius: var(--radius-m);
    border-bottom-right-radius: var(--radius-m);
  }

  .box__info--bordered .box__image {
    border-radius: var(--radius-m);
  }

  .box__image {
    max-width: 100%;
    aspect-ratio: 4 / 2;
  }

  .box--commitment .box__content {
    max-width: 100%;
  }

  .box--single {
    padding-block: 40px 0;
  }

  .box--single .box__image {
    max-width: 100%;
    aspect-ratio: auto;
  }

  .box--single .box__info {
    gap: 24px;
  }

  .box--single .box__content {
    width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .box__image:not(.box__image--long) {
    aspect-ratio: 3 / 2;
  }
}

.hero {
  padding: 0;
}

.hero__inner {
  min-height: 821px;
  background-position: 60px 57%;
  background-repeat: no-repeat;
  background-size: 45%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  max-width: clamp(45%, 45vw, 625px);
  width: 100%;
  flex-direction: column;
  gap: clamp(23px, 10vw, 128px);
  margin-left: auto;
  display: flex;
  position: relative;
}

.hero__content img {
  margin-bottom: clamp(0px, 5vw, 70px);
}

.hero__content .btn {
  width: -moz-fit-content;
  width: fit-content;
}

.hero--home .hero__content {
  gap: 23px;
}

.hero--home .desc {
  margin-bottom: clamp(0px, 2.2vw, 31px);
}

.hero--tours .hero__content {
  gap: clamp(23px, 10vw, 104px);
}

.hero--reverse .hero__inner {
  background-position: 96% 57%;
}

.hero--reverse .hero__content {
  margin-inline: 0 auto;
}

.hero--wildlife .hero__content {
  min-height: 623px;
  justify-content: space-between;
}

@media only screen and (max-width: 1280px) {
  .hero__inner {
    min-height: 600px;
    background-position: 10%;
  }

  .hero__content {
    gap: 30px;
  }

  .hero--wildlife .hero__content {
    min-height: auto;
  }
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 400px;
    background-size: contain;
  }

  .hero__content {
    max-width: 768px;
    margin-inline: 0;
  }

  .hero__content .desc {
    width: 80%;
    color: var(--color-blue-700);
  }
}

@media only screen and (max-width: 576px) {
  .hero__content .desc {
    width: 100%;
  }
}

.accent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-blue-800);
  padding-top: clamp(40px, 5vw, 58px);
  padding-bottom: clamp(40px, 24vw, 304px);
  position: relative;
}

.accent__wrapper {
  position: relative;
}

.accent__wrapper:before {
  content: "";
  background: var(--gradient-primary);
  position: absolute;
  inset: 0;
}

.accent__main {
  border-radius: var(--radius-xl);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-block: clamp(20px, 12.2vw, 174px) clamp(40px, 7vw, 96px);
  padding-inline: clamp(20px, 9vw, 116px);
}

.accent__card {
  max-width: 1088px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  margin-inline: auto;
  padding: clamp(10px, 2.8vw, 40px);
  display: flex;
  position: relative;
}

.accent__card .section__nav {
  margin-bottom: 0;
}

.accent__card .section__nav .btn {
  display: none;
}

.accent__content h3 {
  margin-bottom: 12px;
}

.accent__content p {
  letter-spacing: -.5px;
}

.accent__element {
  z-index: 2;
  max-height: 344px;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.accent__element img {
  width: 100%;
}

@media only screen and (min-width: 1442px) {
  .accent__element img {
    aspect-ratio: 5 / 1;
  }
}

@media only screen and (max-width: 992px) {
  .accent {
    padding-bottom: 0;
  }

  .accent__main, .accent__card {
    border-radius: var(--radius-m);
  }

  .accent__main {
    padding-inline: 20px;
    padding-bottom: 20px;
  }

  .accent__element {
    position: static;
  }

  .accent__element img {
    aspect-ratio: 5 / 2;
  }
}

.card__items {
  gap: 30px clamp(16px, 2.2vw, 30px);
  display: grid;
}

.card__items--one {
  grid-template-columns: 1fr;
  gap: clamp(30px, 7vw, 100px) clamp(16px, 2.2vw, 30px);
}

.card__items--two {
  grid-template-columns: repeat(2, 1fr);
}

.card__items--three {
  grid-template-columns: repeat(3, 1fr);
}

.card__items--btn .card__content {
  margin-bottom: 30px;
}

.card__item {
  padding-bottom: 24px;
}

.card__item--linked {
  box-shadow: none;
  border-radius: var(--radius-main);
  transition: box-shadow .2s ease-in-out;
  position: relative;
}

.card__item--linked:hover {
  box-shadow: var(--shadow-primary);
}

.card__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.card article {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card__image {
  width: 100%;
  border-top-left-radius: var(--radius-main);
  border-top-right-radius: var(--radius-main);
  overflow: hidden;
}

.card__image:not(:last-child) {
  margin-bottom: 24px;
}

.card__image img {
  width: 100%;
}

.card__content, .card_btns {
  padding-inline: clamp(10px, 1.4vw, 20px);
}

.card__content h3 {
  margin-bottom: 20px;
}

.card__content :not(:last-child, h3) {
  margin-bottom: 22px;
}

.card__btns {
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

.card__btns:not(.card__btns--inline) {
  flex-direction: column;
}

.card__reviews, .card__review {
  display: flex;
}

.card__reviews {
  max-width: 1053px;
  width: 100%;
  justify-content: space-between;
  gap: clamp(40px, 8vw, 60px) 20px;
  margin-inline: auto;
}

.card__review {
  max-width: 470px;
  width: 100%;
  gap: clamp(16px, 2.2vw, 30px);
}

.card__review .card__content {
  margin-bottom: 0;
  padding: 0;
}

.card__review .card__content :not(:last-child) {
  margin-bottom: 30px;
}

.card__review .card__content p {
  line-height: 1.11;
}

.card__author {
  text-transform: uppercase;
  font-size: clamp(16px, 1.4vw, 18px);
}

.card__avatar {
  max-width: 70px;
  width: 100%;
}

.card__infos {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(10px, 4.2vw, 60px);
  display: grid;
}

.card__info h3 {
  margin-bottom: 16px;
}

.card__info p {
  font-size: clamp(16px, 1.4vw, 18px);
  line-height: 1.5;
}

.card__icon {
  padding-top: 65px;
  position: relative;
}

.card__icon:before {
  content: "";
  width: 38px;
  height: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.card__icon--eco:before {
  background-image: url("../images/icons/eco-icon.svg");
}

.card__icon--wildlife:before {
  background-image: url("../images/icons/wildlife-icon.svg");
}

.card__icon--community:before {
  background-image: url("../images/icons/community-icon.svg");
}

.card__wrapper {
  width: 100%;
  justify-content: space-between;
  gap: clamp(16px, 2.2vw, 30px);
  padding-inline: clamp(10px, 1.4vw, 20px);
  display: flex;
}

.card__wrapper .card__content {
  padding-inline: 0;
}

.card--explore {
  padding-top: clamp(40px, 11vw, 146px);
}

.card--team .card__image {
  border-radius: var(--radius-main);
}

.card--tabs {
  padding: clamp(30px, 10vw, 110px) 0;
}

@media only screen and (max-width: 992px) {
  .card__btns {
    width: 100%;
  }

  .card__btns--inline {
    flex-direction: column;
  }

  .card__wrapper {
    flex-direction: column;
    padding: 0;
  }

  .card__wrapper .card__btns {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .card__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .card__items--one {
    grid-template-columns: 1fr;
  }

  .card__items--three .card__item:last-of-type {
    grid-column: span 2;
  }

  .card__items--three .card__item:last-of-type .card__image:not(.card__image--long) img {
    aspect-ratio: 4 / 2;
    object-position: bottom;
  }

  .card__items--three .card__item:last-of-type .card__image--long img {
    aspect-ratio: 3 / 2;
    object-position: center;
  }

  .card__item--linked {
    border-radius: var(--radius-m);
  }

  .card__item--linked:hover {
    box-shadow: none;
  }

  .card__image {
    border-top-left-radius: var(--radius-m);
    border-top-right-radius: var(--radius-m);
  }

  .card .btn {
    width: 100%;
  }

  .card--btn .card__items {
    margin-bottom: clamp(40px, 8vw, 60px);
  }

  .card__reviews {
    flex-direction: column;
  }

  .card__review {
    max-width: 100%;
  }

  .card--team .card__image {
    border-radius: var(--radius-m);
  }
}

@media only screen and (max-width: 576px) {
  .card__items, .card__items--three {
    grid-template-columns: 1fr;
  }

  .card__items--three .card__item:last-of-type {
    grid-column: auto;
  }

  .card__items--three .card__item:last-of-type .card__image--long img {
    aspect-ratio: auto;
  }

  .card__image:not(.card__items--three .card__item:last-of-type--long) img {
    aspect-ratio: 3 / 2;
  }

  .card__item {
    padding-bottom: 0;
  }

  .card__image:not(.card__image--long) img {
    aspect-ratio: 3 / 2;
  }

  .card__image--bottom img {
    object-position: bottom;
  }

  .card__review {
    flex-direction: column;
  }

  .card__infos {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .card__icon {
    padding-top: 0;
    padding-left: 40px;
  }

  .card__icon:before {
    width: 30px;
    height: 30px;
    top: 4px;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.faq {
  padding: clamp(30px, 5vw, 52px) 0 clamp(30px, 5vw, 64px);
}

.faq h2 {
  margin-bottom: 48px;
}

.faq__list {
  padding-inline: clamp(0px, 2.6vw, 40px) 0;
}

.faq__item {
  overflow: hidden;
}

.faq__item:not(:last-child) {
  border-bottom: 2px solid var(--color-grey-200);
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.faq details {
  overflow: hidden;
}

.faq details[open] summary {
  padding-block: 0 8px;
  padding-right: 76px;
}

.faq details[open] summary:before {
  rotate: none;
}

.faq details[open] summary + div {
  padding-right: 16px;
}

.faq summary {
  padding-block: 0 8px;
  cursor: pointer;
  padding-right: 76px;
  position: relative;
}

.faq summary:before {
  content: "";
  width: 28px;
  height: 28px;
  background-image: url("../images/icons/collapse-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: rotate .2s ease-in-out, filter .4s ease-in;
  position: absolute;
  top: 6px;
  right: 42px;
  overflow: hidden;
  rotate: 180deg;
}

.faq summary + div {
  padding: 0;
}

@media only screen and (max-width: 992px) {
  .faq summary {
    padding-right: 40px;
  }

  .faq summary:before {
    top: 0;
    right: 16px;
  }

  .faq details[open] summary {
    padding-right: 40px;
  }
}

.tabs__menu {
  max-width: 93vw;
  margin-bottom: clamp(40px, 7vw, 100px);
  overflow-x: scroll;
}

.tabs__nav {
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
  display: flex;
  position: relative;
}

.tabs__nav:before {
  content: "";
  z-index: -1;
  height: 3px;
  background-color: var(--color-grey-200);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabs__nav li {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.tabs__nav span {
  height: 100%;
  width: 100%;
  text-align: center;
  border-bottom: 4px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 11px 4px 6px;
  transition: border-color .2s ease-in-out;
  display: flex;
}

.tabs__nav span.selected {
  border-color: var(--color-blue-700);
}

.tabs__btn {
  font-size: clamp(14px, 2.3vi, 32px);
}

.request {
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: clamp(40px, 8vw, 119px) 0;
}

.request__inner {
  max-width: 817px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  display: flex;
}

.request__inner h2 {
  margin-bottom: 52px;
}

.request__form {
  max-width: 716px;
  width: 100%;
  flex-direction: column;
  gap: 30px;
  display: flex;
  position: relative;
}

.request__form input {
  width: 100%;
  letter-spacing: -.5px;
  background-color: var(--color-white);
  border: 4px solid var(--color-grey-300);
  border-radius: var(--radius-main);
  padding-block: 36px;
  padding-inline: 36px 177px;
  transition: border-color .2s ease-in-out;
  display: block;
}

.request__form input:focus {
  border-color: var(--color-blue-400);
}

.request__form .btn {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 992px) {
  .request__inner h2 {
    width: 80%;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 768px) {
  .request__form input {
    border-width: 2px;
    padding: 20px 16px;
  }

  .request__form .btn {
    position: static;
    transform: translateY(0);
  }
}

.policy {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-400);
  color: var(--color-black);
  padding-block: 50px;
  font-size: 18px;
}

.policy h1, .policy h2 {
  text-transform: capitalize;
}

.policy h1 {
  margin-bottom: 24px;
  font-size: clamp(26px, 3.4vi, 48px);
}

.policy h2 {
  margin-bottom: 16px;
  font-size: clamp(20px, 2vi, 24px);
}

.policy .policy__content:not(:last-child), .policy .policy__content > :not(:last-child, h2) {
  margin-bottom: 32px;
}

.policy ul {
  padding-left: clamp(16px, 3vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  color: var(--color-green-400);
  text-underline-offset: 6px;
  text-decoration: underline;
}

.policy strong {
  font-size: clamp(18px, 3vw, 20px);
  font-weight: var(--fw-500);
}

.footer .nav {
  padding: clamp(40px, 4.2vw, 60px) 0;
}

.footer__wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 16px clamp(0px, 3.5vw, 50px);
  display: flex;
}

.footer__items {
  gap: 48px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .footer__wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }
}

/*# sourceMappingURL=main.css.map */
