@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3,
.title,
summary {
  font-weight: var(--fw-700);
  line-height: 1.2;
  letter-spacing: -0.024em;
  color: var(--color-blue-700);
  overflow: hidden;

  &.bright {
    color: var(--color-white);
  }

  @include media(mobile-l) {
    br {
      display: none;
    }
  }
}

h1 {
  font-size: clamp(32px, 4.5vi, 64px);
}

h2 {
  font-size: clamp(26px, 3.4vi, 48px);
}

h3,
summary {
  font-size: clamp(20px, 2.3vi, 32px);
}

.title {
  font-size: clamp(20px, 2.3vi, 32px);

  @include media(mobile-l) {
    font-weight: var(--fw-600);
  }
}

p {
  line-height: 1.22;
  letter-spacing: -0.5px;
}

input,
textarea {
  font-weight: var(--fw-600);
  font-size: clamp(20px, 2vi, 24px);
  line-height: 1.22;
  letter-spacing: -0.002em;
  color: var(--color-blue-400);

  &::placeholder {
    color: var(--color-grey-400);
    font-weight: var(--fw-500);
  }
}

.link {
  font-weight: var(--fw-500);
  font-size: 16px;
  line-height: 1.22;
  color: var(--color-dark);

  br {
    display: none;
  }

  @include media(mobile-m) {
    line-height: 1.6;
    text-align: center;

    br {
      display: block;
    }
  }
}
