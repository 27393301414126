@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.faq {
  padding: clamp(30px, 5vw, 52px) 0 clamp(30px, 5vw, 64px);

  h2 {
    margin-bottom: 48px;
  }

  &__list {
    padding-inline: clamp(0px, 2.6vw, 40px) 0;
  }

  &__item {
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 2px solid var(--color-grey-200);
    }
  }

  details {
    overflow: hidden;

    &[open] {
      summary {
        padding-block: 0 8px;
        padding-right: 76px;
      }

      summary::before {
        rotate: 0deg;
      }

      summary + div {
        padding-right: 16px;
      }
    }
  }

  summary {
    position: relative;
    padding-block: 0 8px;
    padding-right: 76px;
    cursor: pointer;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      right: 42px;
      top: 6px;
      overflow: hidden;
      width: 28px;
      height: 28px;
      background-image: url("../images/icons/collapse-icon.svg");
      transition: rotate 0.2s ease-in-out, filter 0.4s ease-in;
      rotate: 180deg;
    }

    + div {
      padding: 0;
    }
  }

  @include media(tablet) {
    summary {
      padding-right: 40px;

      &::before {
        top: 0;
        right: 16px;
      }
    }

    details[open] {
      summary {
        padding-right: 40px;
      }
    }
  }
}