@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-width: clamp(150px, 14vw, 201px);
    width: 100%;
    margin-right: clamp(10px, 2.3vw, 32px);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 986px;
    width: 100%;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: stretch;
    gap: 0 clamp(10px, 4.8vw, 66px);
    margin-inline: 0 clamp(10px, 2.3vw, 32px);
  }

  &__item {
    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    font-size: 16px;
    color: var(--color-dark);
    white-space: nowrap;
    transition: border-bottom 0.3s ease-in-out;

    &.true,
    &:hover {
      color: var(--color-green-400);
      border-bottom: 2px solid var(--color-green-400);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: clamp(16px, 1.8vw, 24px);

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &:not(&--footer) {
    .nav__inner {
      @include media(tablet) {
        position: fixed;
        left: 0;
        top: calc(var(--header-height) * 1);
        z-index: -1;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding: 30px 16px;
        height: calc(var(--vh));
        max-width: 100%;
        background-color: var(--color-white);
        transform: translateX(150%);
        transition-property: transform;
        transition-duration: 0.4s;
      }
    }

    .nav__list {
      @include media(tablet) {
        z-index: -1;
        grid-template-columns: auto;
        justify-items: center;
        margin-right: 0;
      }
    }
  }

  &--footer {
    @include media(tablet) {
      flex-direction: column;
      align-items: center;

      .logo {
        margin-bottom: 30px;
        margin-right: 0;
      }

      .nav__inner {
        flex-direction: column;
      }

      .nav__list {
        justify-items: center;
        gap: 16px clamp(10px, 4.8vw, 66px);
        margin-right: 0;
      }

      .nav__item {
        margin-bottom: 0;
      }

      .nav__btns {
        display: none;
      }
    }

    @include media(mobile-m) {
      .nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
      }
    }
  }
}