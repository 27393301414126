@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-style: normal;
  font-size: clamp(16px, 2vi, 20px);
  line-height: 1.22;
  letter-spacing: -0.002em;
  color: var(--color-grey-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }

  &--home {
    .accent .section__nav .btn {
      display: block;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  &--large {
    max-width: var(--container-large);
  }
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: var(--header-height);
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  @include media(tablet) {
    &::before {
      background: var(--gradient-overlay);
      backdrop-filter: blur(2px);
    }
  }
}