@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    min-height: 821px;
    background-position: 60px 57%;
    background-size: 45%;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: clamp(23px, 10vw, 128px);
    max-width: clamp(45%, 45vw, 625px);
    width: 100%;
    margin-left: auto;

    img {
      margin-bottom: clamp(0px, 5vw, 70px);
    }

    .btn {
      width: fit-content;
    }
  }

  &--home {
    .hero__content {
      gap: 23px;
    }

    .desc {
      margin-bottom: clamp(0px, 2.2vw, 31px);
    }
  }

  &--tours {
    .hero__content {
      gap: clamp(23px, 10vw, 104px)
    }
  }

  &--reverse {
    .hero__inner {
      background-position: 96% 57%;
    }

    .hero__content {
      margin-inline: 0 auto;
    }
  }

  &--wildlife {
    .hero__content {
      justify-content: space-between;
      min-height: 623px;
    }
  }

  @include media(desktop) {
    &__inner {
      min-height: 600px;
      background-position: 10% center;
    }

    &__content {
      gap: 30px;
    }

    &--wildlife {
      .hero__content {
        min-height: auto;
      }
    }
  }

  @include media(tablet) {
    &__inner {
      background-size: contain;
      min-height: 400px;
    }

    &__content {
      max-width: 768px;
      margin-inline: 0;

      .desc {
        width: 80%;
        color: var(--color-blue-700);
      }
    }
  }

  @include media(mobile-m) {
    &__content {
      .desc {
        width: 100%;
      }
    }
  }
}