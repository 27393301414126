@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.request {
  @include cover-background;
  padding: clamp(40px, 8vw, 119px) 0;
  min-height: 400px;

  &__inner {
    @include flex-all-center;
    flex-direction: column;
    max-width: 817px;
    width: 100%;
    margin-inline: auto;

    h2 {
      margin-bottom: 52px;
    }
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 716px;
    width: 100%;

    input {
      display: block;
      width: 100%;
      padding-block: 36px;
      padding-inline: 36px 177px;
      letter-spacing: -0.5px;
      background-color: var(--color-white);
      border: 4px solid var(--color-grey-300);
      border-radius: var(--radius-main);
      transition: 0.2s ease-in-out border-color;

      &:focus {
        border-color: var(--color-blue-400);
      }
    }

    .btn {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include media(tablet) {
    &__inner {
      h2 {
        margin-inline: auto;
        width: 80%;
      }
    }
  }

  @include media(mobile-l) {
    &__form {
      input {
        padding: 20px 16px;
        border-width: 2px;
      }

      .btn {
        position: static;
        transform: translateY(0);
      }
    }
  }
}