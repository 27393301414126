@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.accent {
  @include cover-background;

  position: relative;
  padding-top: clamp(40px, 5vw, 58px);
  padding-bottom: clamp(40px, 24vw, 304px);
  background-color: var(--color-blue-800);

  &__wrapper {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--gradient-primary);
    }
  }

  &__main {
    @include cover-background;

    padding-inline: clamp(20px, 9vw, 116px);
    padding-block: clamp(20px, 12.2vw, 174px) clamp(40px, 7vw, 96px);
    border-radius: var(--radius-xl);
  }

  &__card {
    @include flex-sb;
    position: relative;
    flex-direction: column;
    gap: 40px;
    max-width: 1088px;
    width: 100%;
    margin-inline: auto;
    padding: clamp(10px, 2.8vw, 40px);
    background-color: var(--color-white);
    border-radius: var(--radius-main);
    z-index: 1;

    .section__nav {
      margin-bottom: 0;

      .btn {
        display: none;
      }
    }
  }

  &__content {
    h3 {
      margin-bottom: 12px;
    }

    p {
      letter-spacing: -0.5px;
    }
  }

  &__element {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    max-height: 344px;

    img {
      width: 100%;
    }
  }

  @include media(desktop-wide, min) {
    &__element {
      img {
        aspect-ratio: 5 / 1;
      }
    }
  }

  @include media(tablet) {
    padding-bottom: 0;

    &__main,
    &__card {
      border-radius: var(--radius-m);
    }

    &__main {
      padding-inline: 20px;
      padding-bottom: 20px;
    }

    &__element {
      position: static;

      img {
        aspect-ratio: 5/2;
      }
    }
  }
}